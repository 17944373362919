import React, { useEffect, useMemo } from "react";
import { ArrayInput, SimpleFormIterator, TextInput, SelectInput, required, useInput, email, number } from "react-admin";
import { useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import useAddress from "hooks/useAddress";

const SelectAddress = ({
  label,
  source,
  optionValue,
  dependedSource = null,
  defaultValue = null,
  type,
  fullWidth = true,
  className = "",
}) => {
  const current = useInput({ source });
  const { setValue } = useFormContext();
  const dependedField = useMemo(() => {
    if (dependedSource && source) {
      const items = source.split(".");
      if (items === 1) {
        return dependedSource;
      }
      items[items.length - 1] = dependedSource;
      return items.join(".");
    }
    return null;
  }, [source, dependedSource]);

  const { field } = useInput({ source: dependedField ?? "" });
  const { data, isLoading } = useAddress(type, dependedSource ? field.value : null);

  useEffect(() => {
    if (current.field.value) {
      const $select = document.getElementById(source);
      if ($select) {
        setValue(`${source}Name`, $select.textContent);
      }
    }
  }, [current.field, source, setValue]);

  useEffect(() => {
    const $select = document.getElementById(source);
    if ($select) {
      setValue(`${source}Name`, $select.textContent);
    }
  }, [setValue, source]);

  return (
    <SelectInput
      defaultValue={defaultValue}
      label={isLoading ? "Đang tải" : label}
      optionText="name"
      optionValue={optionValue}
      disabled={isLoading}
      fullWidth={fullWidth}
      className={className}
      source={source}
      choices={data ?? []}
    />
  );
};

const ContactSetting = () => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={6}>
        <TextInput label="Số điện thoại" source="phone" validate={[required(), number()]} fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Email" source="email" validate={[required(), email()]} fullWidth />
      </Grid>

      <Grid item xs={12}>
        <p className="font-semibold m-0">Địa chỉ cửa hàng chính</p>
      </Grid>

      <Grid item xs={6}>
        <SelectAddress label="Chọn tỉnh/thành phố" optionValue="idProvince" type="province" source="province" />
      </Grid>

      <Grid item xs={6}>
        <SelectAddress
          label="Chọn quận/huyện"
          optionValue="idDistrict"
          type="district"
          source="district"
          dependedSource="province"
        />
      </Grid>

      <Grid item xs={6}>
        <SelectAddress
          label="Chọn xã/phường"
          optionValue="idCommune"
          source="commune"
          type="commune"
          dependedSource="district"
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Địa chỉ" source="address" validate={[required()]} fullWidth />
      </Grid>

      <Grid item xs={12}>
        <TextInput label="Đường dẫn bản đồ" source="map" fullWidth />
      </Grid>

      <Grid item xs={12}>
        <ArrayInput label="Các cửa hàng khác:" source="branches">
          <SimpleFormIterator disableReordering disableClear inline fullWidth>
            <TextInput label="Tên cửa hàng" source="branchName" className="w-[48%]" />
            <TextInput label="Số điện thoại" source="branchPhone" validate={[number()]} className="w-[48%]" />
            <SelectAddress
              className="!w-[48%]"
              label="Chọn tỉnh/thành phố"
              optionValue="idProvince"
              type="province"
              source="branchProvince"
            />
            <SelectAddress
              className="!w-[48%]"
              label="Chọn quận/huyện"
              optionValue="idDistrict"
              type="district"
              source="branchDistrict"
              dependedSource="branchProvince"
            />
            <SelectAddress
              className="!w-[48%]"
              label="Chọn xã/phường"
              optionValue="idCommune"
              source="branchCommune"
              type="commune"
              dependedSource="branchDistrict"
            />
            <TextInput className="w-[48%]" label="Địa chỉ" source="branchAddress" />

            <TextInput className="w-[97%]" label="Đường dẫn bản đồ" source="branchMap" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  );
};

export default ContactSetting;
