import { List, Datagrid, FunctionField, EditButton, DeleteWithConfirmButton, TextField, DateField } from "react-admin";
import shared from "shared"
import dayjs from "dayjs";

const isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

const TODAY = dayjs();

const { DISCOUNT_TYPES, DISCOUNT_UNIT_TYPES } = shared.Constants;

const StatusField = () => {
  return (
    <FunctionField
      render={record => {
        let valid = true;
        if (record.start && record.end) {
          const { end } = record;
          const endDay = dayjs(end, "YYYY-MM-DD");
          valid = TODAY.isBefore(endDay, "days", true);
        }
        return (
          <span
            style={{ backgroundColor: valid ? "green" : "red" }}
            className="font-semibold text-white py-1 px-2 rounded text-sm"
          >
            {valid ? "Đang hoạt động" : "Hết hạn"}
          </span>
        );
      }}
    />
  );
};

const RecordField = ({ source, data }) => {
  return (
    <FunctionField
      render={record => {
        const selected = Object.values(data).find(v => v.VALUE === record[source]);
        return selected.TEXT;
      }}
    />
  );
};

const ValueField = () => {
  return (
    <FunctionField
      sortable={false}
      render={record => {
        const { unit, value } = record;
        if (unit === DISCOUNT_UNIT_TYPES.PERCENT.VALUE) {
          return `${value}%`;
        }
        return shared.currencyFormat(value);
      }}
    />
  );
};

export const DiscountList = () => (
  <List exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <TextField label="Tên khuyến mãi" source="name" />
      <RecordField data={DISCOUNT_TYPES} label="Loại khuyến mãi" source="discountType" />
      <StatusField label="Trạng thái" />
      <ValueField label="Giảm giá" source="id" />
      <DateField locales="vi-VN" label="Ngày bắt đầu" source="start" />
      <DateField locales="vi-VN" label="Ngày kết thúc" source="end" />
      <DeleteWithConfirmButton
        confirmTitle="Bạn có muốn xóa dữ liệu này?"
        confirmContent="Bạn sẽ không thể khôi phục dữ liệu sau khi xóa"
      />
      <EditButton />
    </Datagrid>
  </List>
);
