import useSWRMutation from "swr/mutation";
import http from "providers/HttpProvider";

const fetchUpdateOrder = async (_, { arg }) => {
  return http.put(`/orders/${arg.id}`, arg);
};

export const useOrderUpdate = ({ onSuccess }) => {
  const { trigger, isMutating, error } = useSWRMutation("update-order", fetchUpdateOrder, { onSuccess });
  return {
    trigger,
    isMutating,
    error,
  };
};
