import 'dayjs/locale/vi';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const FORMAT_TYPE = 'YYYY-MM-DD';

const RangeDatePicker = ({ defaultValues, maxDate, minDate }) => {
  const isSetDefaultValue = useRef(false);
  const { setValue } = useFormContext();
  const initialValues = useMemo(() => {
    return {
      start: defaultValues.start
        ? dayjs(defaultValues.start, FORMAT_TYPE)
        : null,
      end: defaultValues.end ? dayjs(defaultValues.end, FORMAT_TYPE) : null,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [values, setValues] = useState(initialValues);

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    });
    setValue(field, value.format(FORMAT_TYPE), {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  useEffect(() => {
    // Handle the case reset button clicked
    if (isSetDefaultValue) {
      if (!defaultValues.start && !defaultValues.end) {
        setValues({
          start: initialValues.start,
          end: initialValues.end,
        });
      }
    } else {
      // Handle the case parse default values from URL
      if (defaultValues.start && defaultValues.end) {
        setValues({
          start: dayjs(defaultValues.start, FORMAT_TYPE),
          end: dayjs(defaultValues.end, FORMAT_TYPE),
        });
        isSetDefaultValue.current = true;
      }
    }
  }, [
    defaultValues.start,
    defaultValues.end,
    initialValues.start,
    initialValues.end,
  ]);

  return (
    <LocalizationProvider adapterLocale="vi" dateAdapter={AdapterDayjs}>
      <div className="grid grid-cols-2 gap-4">
        <DatePicker
          minDate={minDate}
          maxDate={maxDate}
          value={values.start}
          onChange={(value) => handleChange('start', value)}
          label="Từ ngày"
        />
        <DatePicker
          minDate={minDate}
          maxDate={maxDate}
          value={values.end}
          onChange={(value) => handleChange('end', value)}
          label="Đến ngày"
        />
      </div>
    </LocalizationProvider>
  );
};

export default RangeDatePicker;
