import { List, Datagrid, EditButton, DeleteWithConfirmButton, TextField, DateField } from "react-admin";

export const PageList = () => (
  <List exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <DateField locales="vi-VN" label="Ngày tạo" source="createdAt" />
      <TextField label="Tiêu đề" source="name" />
      <DeleteWithConfirmButton
        confirmTitle="Bạn có muốn xóa dữ liệu này?"
        confirmContent="Bạn sẽ không thể khôi phục dữ liệu sau khi xóa"
      />
      <EditButton />
    </Datagrid>
  </List>
);
