import ColorLensIcon from "@mui/icons-material/ColorLens";
import { AppearanceList } from "./List";

const AppearanceResource = {
  list: AppearanceList,
  name: "appearance",
  options: { label: "Giao diện" },
  icon: ColorLensIcon,
};

export default AppearanceResource;
