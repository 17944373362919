import { List, Datagrid, FunctionField, EditButton, DeleteWithConfirmButton, TextField } from "react-admin";

const ImageField = ({ source, width, height }) => {
  return (
    <FunctionField
      label="Name"
      render={record => {
        if (record[source]) {
          return <img height={height} width={width} alt="" src={record[source].src} />;
        }
        return null;
      }}
    />
  );
};

export const CategoryList = () => (
  <List exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <TextField label="Tên danh mục" source="name" />
      <ImageField width={20} height={20} label="Icon" source="icon" />
      <ImageField height={40} sx={{ width: 50 }} title="Banner" source="banner" />
      <DeleteWithConfirmButton
        confirmTitle="Bạn có muốn xóa dữ liệu này?"
        confirmContent="Bạn sẽ không thể khôi phục dữ liệu sau khi xóa"
      />
      <EditButton />
    </Datagrid>
  </List>
);
