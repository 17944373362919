import React, { forwardRef } from "react";
import shared from "shared";
import { SimpleForm, TextInput, required, Toolbar, SaveButton, PasswordInput, email } from "react-admin";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { useCreateOwner } from "hooks/useOwner";
import LoadingProgress from "components/LoadingProgress";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MyToolbar = ({ isLoading, close }) => {
  return (
    <>
      <Toolbar className="flex-col py-3">
        <SaveButton
          disabled={isLoading}
          icon={isLoading ? <LoadingProgress /> : null}
          type="submit"
          fullWidth
          label="TẠO TÀI KHOẢN"
        />
        <Button
          disabled={isLoading}
          onClick={close}
          startIcon={<CloseIcon />}
          style={{ marginTop: 10 }}
          color="error"
          fullWidth
          variant="outlined"
        >
          HỦY
        </Button>
      </Toolbar>
    </>
  );
};

const RegisterOwnerForm = ({ isOpen, close }) => {
  const { trigger, isMutating } = useCreateOwner(close);

  const handleSubmit = async values => {
    try {
      await trigger({
        name: values.ownerName,
        email: values.ownerEmail,
        password: values.ownerPassword,
        role: shared.Constants.ROLE_TYPES.OWNER,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog disableEscapeKeyDown={true} open={isOpen} TransitionComponent={Transition} keepMounted>
      <DialogContent style={{ padding: 0 }}>
        <SimpleForm
          className="min-w-[400px]"
          toolbar={<MyToolbar isLoading={isMutating} close={close} />}
          onSubmit={handleSubmit}
        >
          <TextInput fullWidth validate={[required()]} label="Tên" source="ownerName" />
          <TextInput fullWidth validate={[required(), email()]} label="Email" source="ownerEmail" />
          <PasswordInput fullWidth validate={[required()]} label="Mật khẩu" source="ownerPassword" />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};

export default RegisterOwnerForm;
