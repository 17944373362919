import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { CustomerList } from "./List";

const CustomerResource = {
  list: CustomerList,
  name: "customers",
  options: { label: "Khách hàng" },
  icon: PeopleAltIcon,
};

export default CustomerResource;
