import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

const theme = createTheme({
  palette: {
    primary: {
      main: "#eaeaea",
    },
  },
});

export default function LoadingProgress() {
  return (
    <ThemeProvider theme={theme}>
      <CircularProgress color="primary" size={18} thickness={2} />
    </ThemeProvider>
  );
}
