import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  required,
  Toolbar,
  SaveButton,
} from 'react-admin';
import React from 'react';
import TextEditor from 'components/FormControls/TextEditor';
import ImagePicker from 'components/FormControls/ImagePicker';

const handleTransform = (values) => {
  const $root = document.getElementById('root');
  const para = document.createElement('div');
  para.id = 'tinymce_content';
  para.className = 'hidden';
  para.innerHTML = values.content;
  $root.appendChild(para);
  const $tinymce = document.getElementById('tinymce_content');

  if ($tinymce && $tinymce.querySelector('p')) {
    const text = $tinymce.querySelector('p').textContent.split('.');
    setTimeout(() => {
      $tinymce.remove();
    }, 1000);
    return {
      ...values,
      description: text?.length > 0 ? text[0] : '',
    };
  }
  return {
    ...values,
    description: '',
  };
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const Form = () => {
  return (
    <SimpleForm toolbar={<FormToolbar />}>
      <TextInput
        label="Tiều đề"
        source="title"
        validate={[required()]}
        fullWidth
      />
      <ImagePicker
        validate={[required()]}
        source="thumbnail"
        label="Hình ảnh hiển thị - Thumbnail"
      />
      <TextEditor label="Mô tả" source="content" />
    </SimpleForm>
  );
};

export const NewsEdit = () => {
  return (
    <Edit
      transform={handleTransform}
      mutationMode="pessimistic"
      redirect="list"
      title="Chỉnh sửa"
    >
      <Form />
    </Edit>
  );
};

export const NewsCreate = () => {
  return (
    <Create transform={handleTransform} redirect="list">
      <Form />
    </Create>
  );
};
