import { List, Datagrid, DeleteWithConfirmButton, TextField, DateField } from "react-admin";

export const CustomerList = () => (
  <List exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <DateField locales="vi-VN" label="Ngày tạo" source="createdAt" />
      <TextField label="Họ tên" source="name" />
      <TextField label="Số điện thoại" source="phone" />
      <TextField label="Địa chỉ" source="address" />
      <DeleteWithConfirmButton
        confirmTitle="Bạn có muốn xóa dữ liệu này?"
        confirmContent="Bạn sẽ không thể khôi phục dữ liệu sau khi xóa"
      />
    </Datagrid>
  </List>
);
