import { AutocompleteInput } from "react-admin";
import useListPagination from "hooks/useListPagination";
import throttle from "lodash/throttle";

const SelectResourceItems = ({ label, source, multiple = false, resource }) => {
  const { data, isLoading, setSize, size } = useListPagination(resource);

  const handleScrollEnd = throttle(() => {
    setSize(size + 1);
  }, 500);

  return (
    <AutocompleteInput
      multiple={multiple}
      label={label}
      source={source}
      choices={data ?? []}
      optionText="name"
      optionValue="id"
      isLoading={isLoading}
      ListboxProps={{
        onScroll: event => {
          const listboxNode = event.currentTarget || event.target;
          if (Math.floor(listboxNode.scrollTop + listboxNode.clientHeight) === listboxNode.scrollHeight) {
            handleScrollEnd();
          }
        },
      }}
    />
  );
};

export default SelectResourceItems;
