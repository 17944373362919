import React, { useMemo } from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  required,
  Toolbar,
  SaveButton,
  RadioButtonGroupInput,
  useInput,
  NumberInput,
  minValue,
  maxValue,
} from "react-admin";
import dayjs from "dayjs";
import shared from "shared";
import SelectResourceItems from "includes/SelectResourceItems";
import CurrencyInput from "components/FormControls/CurrencyInput";
import RangeDatePicker from "components/FormControls/RangeDatePicker";

const { DISCOUNT_TYPES, DISCOUNT_UNIT_TYPES, DISCOUNT_PRODUCT_TYPES, DISCOUNT_CONDITION_TYPES } = shared.Constants;

const generateOptions = data => {
  return Object.values(data).map(item => {
    return {
      id: item.VALUE,
      name: item.TEXT,
    };
  });
};

const TODAY = dayjs();

const DEFAULT_VALUES = {
  discountType: DISCOUNT_TYPES.ORDER.VALUE,
  unit: DISCOUNT_UNIT_TYPES.PERCENT.VALUE,
  productType: DISCOUNT_PRODUCT_TYPES.PRODUCT.VALUE,
  conditionType: DISCOUNT_CONDITION_TYPES.NONE.VALUE,
  start: TODAY,
  end: null,
};

const DISCOUNT_OPTIONS = generateOptions(DISCOUNT_TYPES);

const PRODUCT_OPTIONS = generateOptions(DISCOUNT_PRODUCT_TYPES);

const UNIT_OPTIONS = generateOptions(DISCOUNT_UNIT_TYPES);

const CONDITION_OPTIONS = generateOptions(DISCOUNT_CONDITION_TYPES);

const FormToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const Label = ({ title }) => {
  return (
    <legend className="text-xs text-gray-600">
      <span>{title}</span>
    </legend>
  );
};

const ValueInputs = () => {
  const { field } = useInput({ source: "unit" });

  return (
    <>
      <Label title="Loại giảm giá" />
      <div className="flex items-start">
        <div className="w-[240px] h-[84px]">
          <RadioButtonGroupInput label={false} source="unit" choices={UNIT_OPTIONS} />
        </div>

        <div className="w-[350px]">
          {field.value === "value" && (
            <CurrencyInput label="Giá trị giảm giá" source="value" validate={[required()]} fullWidth />
          )}
          {field.value === DISCOUNT_UNIT_TYPES.PERCENT.VALUE && (
            <NumberInput
              label="Giá trị giảm giá"
              source="value"
              validate={[required(), minValue(1), maxValue(100)]}
              fullWidth
            />
          )}
        </div>
      </div>
    </>
  );
};

const SelectedDiscountProducts = () => {
  const { field: discountType } = useInput({ source: "discountType" });
  const { field: productType } = useInput({ source: "productType" });

  if (discountType.value === DISCOUNT_TYPES.ORDER.VALUE || discountType.value === DISCOUNT_TYPES.SHIPPING.VALUE) {
    return null;
  }
  return (
    <div className="w-full">
      <RadioButtonGroupInput
        helperText={false}
        label="Sản phẩm áp dụng"
        source="productType"
        choices={PRODUCT_OPTIONS}
      />
      {productType.value === DISCOUNT_PRODUCT_TYPES.PRODUCT.VALUE && (
        <SelectResourceItems multiple={true} label="Chọn sản phẩm" source="products" resource="products" />
      )}
      {productType.value === DISCOUNT_PRODUCT_TYPES.CATEGORY.VALUE && (
        <SelectResourceItems multiple={true} label="Chọn nhóm sản phẩm" source="categories" resource="categories" />
      )}
    </div>
  );
};

const ConditionInputs = () => {
  const { field: conditionType } = useInput({ source: "conditionType" });
  return (
    <div className="flex items-end">
      <div className="w-[350px]">
        <RadioButtonGroupInput
          label="Điền kiện áp dụng"
          helperText={false}
          source="conditionType"
          choices={CONDITION_OPTIONS}
        />
      </div>

      {conditionType.value === DISCOUNT_CONDITION_TYPES.LIMIT.VALUE && (
        <div className="w-[350px]">
          <CurrencyInput label="Số tiền tối thiểu" source="limitValue" validate={[required()]} fullWidth />
        </div>
      )}
    </div>
  );
};

const DateInputs = () => {
  const start = useInput({ source: "start" });
  const end = useInput({ source: "end" });
  const defaultValues = useMemo(() => {
    return {
      start: start.field.value,
      end: end.field.value,
    };
  }, [start, end]);

  return (
    <div className="mt-1 w-full">
      <Label title="Thời gian áp dụng (Để trống nếu không có thời gian kết thúc)" />
      <RangeDatePicker minDate={TODAY} defaultValues={defaultValues} />
    </div>
  );
};

const Form = ({ defaultValues = {} }) => {
  return (
    <SimpleForm defaultValues={defaultValues} toolbar={<FormToolbar />}>
      <TextInput label="Mã khuyến mãi" source="name" validate={[required()]} fullWidth />
      <RadioButtonGroupInput
        helperText={false}
        label="Loại khuyến mãi"
        source="discountType"
        choices={DISCOUNT_OPTIONS}
      />
      <SelectedDiscountProducts />
      <ValueInputs />
      <ConditionInputs />
      <DateInputs />
    </SimpleForm>
  );
};

export const DiscountEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect="list" title="Chỉnh sửa">
      <Form />
    </Edit>
  );
};

export const DiscountCreate = () => {
  return (
    <Create redirect="list">
      <Form defaultValues={DEFAULT_VALUES} />
    </Create>
  );
};
