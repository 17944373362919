import { TextInput } from 'react-admin';
import InputAdornment from '@mui/material/InputAdornment';
import shared from 'shared';

const CurrencyInput = ({
  label = 'Giá tiền',
  source = 'price',
  helperText = false,
  ...props
}) => {
  const handleFormat = (text) => {
    return shared.toCurrentInputString(text, ',');
  };

  const handleParse = (text) => {
    const value = text.replaceAll(',', '');
    if (!value) {
      return 0;
    }
    return parseInt(value);
  };

  return (
    <TextInput
      {...props}
      helperText={helperText}
      format={handleFormat}
      parse={handleParse}
      label={label}
      source={source}
      InputProps={{
        endAdornment: <InputAdornment position="end">đ</InputAdornment>,
      }}
    />
  );
};

export default CurrencyInput;
