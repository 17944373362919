import { useMemo } from "react";
import {
  Edit,
  Create,
  TabbedForm,
  TextInput,
  required,
  SimpleFormIterator,
  ArrayInput,
  NumberInput,
  SelectInput,
  useInput,
  useEditContext,
  Toolbar,
  SaveButton,
} from "react-admin";
import Grid from "@mui/material/Grid";
import TextEditor from "components/FormControls/TextEditor";
import CurrencyInput from "components/FormControls/CurrencyInput";
import SelectResourceInput from "components/FormControls/SelectResourceInput";
import ImagePicker from "components/FormControls/ImagePicker";
import useAppConfig from "hooks/useAppConfig";

const FormToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const handleTransform = values => {
  return {
    ...values,
    price: values.primaryPrice,
    weight: values.primaryWeight,
  };
};

const DEFAULT_VARIANT_TYPES = [
  { name: "Kích thước", id: "Kích thước" },
  { name: "Kiểu dáng", id: "Kiểu dáng" },
  { name: "Màu sắc", id: "Màu sắc" },
  { name: "Trọng lượng", id: "Trọng lượng" },
];

const VariantsForm = ({ type }) => {
  const price = useInput({ source: "primaryPrice" });
  const weight = useInput({ source: "primaryWeight" });

  return (
    <ArrayInput fullWidth label="Thuộc tính" source="variants">
      <SimpleFormIterator
        getItemLabel={index => (
          <p className="pt-4">
            <b>#{index + 1}</b>
          </p>
        )}
        fullWidth
        inline
      >
        <SelectInput
          className="w-[23%]"
          onCreate={() => {
            const value = prompt("Nhập giá trị của loại mới");
            const variant = { id: value, name: value };
            DEFAULT_VARIANT_TYPES.push(variant);
            return variant;
          }}
          label="Loại"
          source="type"
          choices={DEFAULT_VARIANT_TYPES}
        />
        <TextInput className="w-[23%]" label="Giá trị hiển thị" source="value" />
        {type === "edit" && <CurrencyInput className="w-[23%]" source="price" />}
        {type === "edit" && <NumberInput className="w-[23%]" label="Trọng lượng (g)" source="weight" />}
        {type === "create" && (
          <CurrencyInput className="w-[23%]" defaultValue={price ? price.field.value : undefined} source="price" />
        )}
        {type === "create" && (
          <NumberInput
            className="w-[23%]"
            defaultValue={weight ? weight.field.value : undefined}
            label="Trọng lượng (g)"
            source="weight"
          />
        )}
        <ImagePicker source="image" label="Hình ảnh biến thể" />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const PrimaryForm = () => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={12}>
        <TextInput label="Tiêu đề" source="name" validate={[required()]} fullWidth />
      </Grid>

      <Grid item xs={6}>
        <CurrencyInput
          source="primaryPrice"
          helperText="Số tiền khách hàng cần thanh toán"
          validate={[required()]}
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <CurrencyInput
          source="comparePrice"
          label="Giá so sánh"
          fullWidth
          helperText="Số tiền chưa giảm giá, thể hiện giá trị giảm giá, ưu đãi cho khách hàng"
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Mã sản phẩm" source="sku" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <SelectResourceInput label="Danh mục" source="categoryId" resource="categories" validate={[required()]} />
      </Grid>

      <Grid item xs={12}>
        <TextEditor label="Mô tả sản phẩm" source="description" />
      </Grid>

      <Grid item xs={12}>
        <ImagePicker validate={[required()]} label="Hình ảnh đại diện sản phẩm" source="thumbnail" />
      </Grid>

      <Grid item xs={12}>
        <ImagePicker validate={[required()]} label="Những hình ảnh khác" multiple source="images" />
      </Grid>
    </Grid>
  );
};

const SecondaryForm = () => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={6}>
        <TextInput label="Thương hiệu" source="vendor" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <NumberInput label="Trọng lượng (g)" fullWidth xs={6} source="primaryWeight" />
      </Grid>
      <Grid item xs={12}>
        <TextInput multiline rows={2} label="Nhãn" source="tags" fullWidth />
      </Grid>

      <Grid item xs={12}>
        <TextEditor height={350} label="Khuyến mãi, ưu đãi" source="promotion" />
      </Grid>
    </Grid>
  );
};

const Form = ({ type, defaultValues = {} }) => {
  return (
    <TabbedForm toolbar={<FormToolbar />} defaultValues={defaultValues}>
      <TabbedForm.Tab label="Thông tin cơ bản">
        <PrimaryForm />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Thông tin khác">
        <SecondaryForm />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Biến thể sản phẩm">
        <VariantsForm type={type} />
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

const EditForm = () => {
  const { record } = useEditContext();
  const defaultValues = useMemo(() => {
    return {
      ...record,
      primaryPrice: record.price,
      primaryWeight: record.weight,
    };
  }, [record]);
  return <Form defaultValues={defaultValues} type="edit" />;
};

const CreateForm = () => {
  const config = useAppConfig();
  const defaultValues = useMemo(() => {
    return {
      promotion: config?.data?.commonPromotion,
    };
  }, [config]);
  return <Form defaultValues={defaultValues} type="create" />;
};

export const ProductEdit = () => {
  return (
    <Edit transform={handleTransform} mutationMode="pessimistic" redirect="list" title="Chỉnh sửa">
      <EditForm />
    </Edit>
  );
};

export const ProductCreate = () => {
  return (
    <Create transform={handleTransform} redirect="list">
      <CreateForm />
    </Create>
  );
};
