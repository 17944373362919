import dayjs from "dayjs";
import { List, Datagrid, TextField } from "react-admin";

export const LogList = () => (
  <List queryOptions={{ meta: { level: "error", date: dayjs().format("YYYY-MM-DD") } }} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <TextField label="Thời gian" source="timestamp" />
      <TextField label="Nội dung lỗi" source="message" />
    </Datagrid>
  </List>
);
