import React, { useMemo } from "react";
import { Layout, AppBar, UserMenu, Logout, Menu, Admin, defaultTheme } from "react-admin";
import LockIcon from "@mui/icons-material/Lock";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import polyglotI18nProvider from "ra-i18n-polyglot";
import vi from "language/vi";
import AppProvider from "providers/AppProvider";
import AuthProvider from "providers/AuthProvider";
import Login from "pages/Login";

const i18nProvider = polyglotI18nProvider(() => vi, "vi");

const MyUserMenu = () => {
  return (
    <UserMenu>
      <Menu.Item to="/change-password" primaryText="Đổi mật khẩu" leftIcon={<LockIcon />} />
      <Logout />
    </UserMenu>
  );
};

const MyAppBar = props => {
  const location = useLocation();

  const title = useMemo(() => {
    if (location.pathname.includes("/change-password")) {
      return "Thay đổi mật khẩu";
    }
    if (location.pathname.includes("/setting")) {
      return "Cài đặt";
    }
    if (location.pathname.includes("/payment")) {
      return "Thanh toán";
    }
    if (location.pathname.includes("/custom-pages")) {
      return "Nội dung trang";
    }
    if (location.pathname.includes("/appearance")) {
      return "Giao diện";
    }
    return null;
  }, [location.pathname]);

  if (!title) {
    return <AppBar {...props} color="primary" userMenu={<MyUserMenu />} />;
  }

  return (
    <AppBar color="primary" {...props} userMenu={<MyUserMenu />}>
      <div style={{ flexGrow: 1 }}>
        <Typography variant="h6" color="inherit">
          {title}
        </Typography>
      </div>
    </AppBar>
  );
};

const MainLayout = props => {
  return <Layout {...props} appBar={MyAppBar} />;
};

const Main = ({ children }) => {
  const theme = useMemo(() => {
    return {
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: {
          ...defaultTheme.palette.primary,
          main: "#461B10",
        },
      },
    };
  }, []);

  return (
    <Admin
      requireAuth
      loginPage={Login}
      layout={MainLayout}
      theme={theme}
      authProvider={AuthProvider}
      i18nProvider={i18nProvider}
      dataProvider={AppProvider}
    >
      {children}
    </Admin>
  );
};

export default Main;
