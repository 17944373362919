import { Edit, Create, SimpleForm, TextInput, Toolbar, SaveButton, required } from "react-admin";
import React from "react";
import TextEditor from "components/FormControls/TextEditor";

const FormToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const Form = () => {
  return (
    <SimpleForm toolbar={<FormToolbar />}>
      <TextInput validate={[required()]} fullWidth source="name" label="Tên trang" />
      <TextInput
        fullWidth
        placeholder="Ví dụ: card_giftcard"
        helperText={
          <a
            rel="noopener noreferrer"
            className="mb-5 block"
            target="_blank"
            href="https://fonts.google.com/icons?icon.set=Material+Icons"
          >
            <span>Hãy truy cập vào đường dẫn này để tìm icon phù hợp</span>
          </a>
        }
        source="icon"
        label="Icon hiển thị"
      />
      <TextEditor height={600} width="calc(100vw - 304px)" label="Nội dung" source="content" />
    </SimpleForm>
  );
};

export const PageEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect="list" title="Chỉnh sửa">
      <Form />
    </Edit>
  );
};

export const PageCreate = () => {
  return (
    <Create redirect="list">
      <Form />
    </Create>
  );
};
