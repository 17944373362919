import SettingsIcon from "@mui/icons-material/Settings";
import { SettingList } from "./List";

const SettingResource = {
  list: SettingList,
  name: "setting",
  options: { label: "Cài đặt" },
  icon: SettingsIcon,
};

export default SettingResource;
