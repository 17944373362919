import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { OrderList } from "./List";

const OrderResource = {
  list: OrderList,
  name: "orders",
  options: { label: "Đơn hàng" },
  icon: ShoppingCartIcon,
};

export default OrderResource;
