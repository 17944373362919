import React from "react";
import { TextInput, required, email, number } from "react-admin";
import Grid from "@mui/material/Grid";

const StoreSetting = () => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={12}>
        <TextInput label="Tên cửa hàng" source="name" validate={[required()]} fullWidth />
      </Grid>

      <Grid item xs={12}>
        <TextInput multiline rows={3} label="Giới thiệu" source="description" validate={[required()]} fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Số điện thoại" source="phone" validate={[required(), number()]} fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Email" source="email" validate={[required(), email()]} fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Facebook" source="facebook" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Zalo" source="zalo" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Youtube" source="youtube" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Tiktok" source="tiktok" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Shopee" source="shopee" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Lazada" source="lazada" fullWidth />
      </Grid>
    </Grid>
  );
};

export default StoreSetting;
