import { useCallback } from "react";
import useSWRMutation from "swr/mutation";
import { useNotify, useRedirect } from "react-admin";
import http from "providers/HttpProvider";
import AuthProvider from "providers/AuthProvider";

const fetchForgotPassword = async (_, { arg }) => {
  return http.post("/auth/forgot-password", arg);
};

const fetchResetPassword = async (_, { arg }) => {
  return http.post("/auth/reset-password", arg);
};

const fetchChangePassword = async (_, { arg }) => {
  return http.post("/auth/change-password", arg);
};

const fetchLogin = async (_, { arg }) => {
  return AuthProvider.login(arg);
};

export const useLogin = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSuccess = useCallback(() => {
    redirect("/");
  }, [redirect]);

  const handleError = useCallback(
    error => {
      notify(error.message || "Tài khoản hoặc mật khẩu không chính xác", {
        type: "error",
      });
    },
    [notify],
  );

  const { trigger, isMutating } = useSWRMutation("submmit-login", fetchLogin, {
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return {
    trigger,
    isMutating,
  };
};

export const useResetPassword = () => {
  const redirect = useRedirect();
  const notify = useNotify();

  const handleSuccess = useCallback(() => {
    notify("Mật khẩu đã được khôi phục thành công", {
      type: "success",
    });
    redirect("/login");
  }, [notify, redirect]);

  const handleError = useCallback(
    error => {
      notify(error.message || "Lỗi khi khôi phục mật khẩu, vui lòng thử lại sau", {
        type: "error",
      });
    },
    [notify],
  );

  const { trigger, isMutating } = useSWRMutation("submmit-reset-password", fetchResetPassword, {
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return {
    trigger,
    isMutating,
  };
};

export const useForgotPassword = () => {
  const notify = useNotify();

  const handleSuccess = useCallback(() => {
    notify("Vui lòng kiểm tra hộp thư của bạn để tiếp tục", {
      type: "success",
    });
  }, [notify]);

  const handleError = useCallback(
    error => {
      notify(error.message || "Lỗi khi khôi phục mật khẩu, vui lòng thử lại sau", {
        type: "error",
      });
    },
    [notify],
  );

  const { trigger, isMutating } = useSWRMutation("submmit-forgot-password", fetchForgotPassword, {
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return {
    trigger,
    isMutating,
  };
};

export const useChangePassword = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSuccess = useCallback(() => {
    notify("Mật khẩu đã được thay đổi thành công", {
      type: "success",
    });
    redirect("/");
  }, [notify, redirect]);

  const handleError = useCallback(
    error => {
      notify(error.message || "Lỗi khi khôi phục mật khẩu, vui lòng thử lại sau", {
        type: "error",
      });
    },
    [notify],
  );

  const { trigger, isMutating } = useSWRMutation("submmit-change-password", fetchChangePassword, {
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return {
    trigger,
    isMutating,
  };
};
