import { useMemo } from "react";
import useSWR from "swr";
import http from "providers/HttpProvider";
import shared from "shared";

const fetcher = async () => {
  const { data } = await http.get("/common/setting");
  return data;
};

const useAppConfig = () => {
  const { data: response, ...rest } = useSWR("app-config", fetcher, {
    keepPreviousData: true,
    revalidateOnFocus: false,
    onSuccess: data => {
      localStorage.setItem("app-config", JSON.stringify(data.data));
    },
  });

  const data = useMemo(() => {
    const stringConfig = localStorage.getItem("app-config");
    const config = shared.stringToJSON(stringConfig);
    if (!response && config) {
      return { data: config };
    }
    if (response) {
      return response;
    }
    return {
      data: {},
    };
  }, [response]);

  return {
    ...data,
    ...rest,
  };
};

export default useAppConfig;
