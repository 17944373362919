import ListIcon from "@mui/icons-material/List";
import { LogList } from "./List";

const LogResource = {
  list: LogList,
  name: "logs",
  options: { label: "Nhật kí" },
  icon: ListIcon,
};

export default LogResource;
