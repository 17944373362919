import NewspaperIcon from "@mui/icons-material/Newspaper";
import { NewsList } from "./List";
import { NewsCreate, NewsEdit } from "./CreateEdit";

const NewsResource = {
  list: NewsList,
  create: NewsCreate,
  edit: NewsEdit,
  name: "news",
  options: { label: "Tin tức" },
  icon: NewspaperIcon,
};

export default NewsResource;
