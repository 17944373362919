import useAppConfig from "hooks/useAppConfig";
import React from "react";

const AuthLayout = ({ children, title }) => {
  const { data: config } = useAppConfig();
  return (
    <div className="w-screen h-screen pt-40 bg-gradient">
      <div className="max-w-[400px] mx-auto">
        <div className="bg-white block shadow">
          <div className="flex items-center justify-center w-full mb-5">
            {config && config.logo?.src && (
              <div className="flex items-center justify-center w-full mb-5">
                <img className="mt-5" src={config.logo.src} height={50} alt="" />
              </div>
            )}
          </div>
          <h3 className="text-center mb-0">{title}</h3>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
