import { useCallback } from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { useNotify } from "react-admin";
import http from "providers/HttpProvider";

const fetchGet = async () => {
  const { data } = await http.get("/auth/owner");
  return data;
};

const fetchSubmit = async (_, { arg }) => {
  return http.post("/auth/owner", arg);
};

export const useCheckOwner = () => {
  const { data, ...rest } = useSWR("check-owner", fetchGet);
  return {
    ...data,
    ...rest,
  };
};

export const useCreateOwner = close => {
  const notify = useNotify();

  const handleSuccess = useCallback(() => {
    close();
    notify("Người dùng đã tạo thành công", {
      type: "success",
    });
  }, [close, notify]);

  const handleError = useCallback(() => {
    notify("Lỗi khi tạo người dùng, vui lòng thử lại sau", {
      type: "error",
    });
  }, [notify]);

  const { trigger, isMutating } = useSWRMutation("submmit-create-owner", fetchSubmit, {
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return {
    trigger,
    isMutating,
  };
};
