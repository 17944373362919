import React from "react";
import {
  List,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  Pagination,
  NumberField,
  useListContext,
  ImageField,
  FunctionField,
} from "react-admin";
import shared from "shared";
import ProductFilter from "includes/ProductFilter";

const NameField = () => {
  return (
    <FunctionField
      render={record => {
        const link = `${process.env.REACT_APP_WEB_URL}/san-pham/${record.slug}`;
        return (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a target="_blank" href={link}>
            {record.name}
          </a>
        );
      }}
    />
  );
};

const ListItems = () => {
  const { isLoading } = useListContext();

  return (
    <Datagrid isLoading={isLoading} optimized bulkActionButtons={false}>
      <ImageField sortable={false} source="thumbnail.src" label="Hình ảnh" />
      <NameField sortable={false} label="Tiêu đề" source="name" />
      <NumberField
        sortable={true}
        label="Giá bán"
        source="price"
        locales="vi-VN"
        options={{ style: "currency", currency: "VND" }}
      />
      <DateField width={100} label="Ngày tạo" source="createdAt" />
      <DeleteWithConfirmButton
        confirmTitle="Bạn có muốn xóa dữ liệu này?"
        confirmContent="Bạn sẽ không thể khôi phục dữ liệu sau khi xóa"
      />
      <EditButton />
    </Datagrid>
  );
};

export const ProductList = () => {
  return (
    <List
      actions={<ProductFilter />}
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={shared.Constants.PAGE_SIZE}
      pagination={<Pagination rowsPerPageOptions={[shared.Constants.PAGE_SIZE]} />}
      exporter={false}
    >
      <ListItems />
    </List>
  );
};
