import React, { useMemo } from "react";
import { CreateButton, TopToolbar, useListContext, SelectArrayInput, useGetList } from "react-admin";
import shared from "shared";
import { Box, Button } from "@mui/material";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import usePriceFilter from "hooks/usePriceFilter";
import Search from "components/FormControls/Search";

const DEFAULT_FILTER = {
  q: "",
  categories: [],
  price: [],
};

const CategoryFilters = () => {
  const { data } = useGetList(
    "categories",
    {},
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
    },
  );
  return (
    <div>
      <SelectArrayInput
        helperText={false}
        optionText="name"
        optionValue="id"
        fullWidth
        label="Danh mục"
        source="categories"
        choices={data ?? []}
      />
    </div>
  );
};

const PriceFilters = ({ filters }) => {
  const { setValue } = useFormContext();

  const handleChange = event => {
    const { value, name } = event.target;
    const { min, max } = shared.getSelectedPriceFilter(value);
    const selectedPriceItems = shared.parsePriceFilter(filters, `${min},${max}`);
    setTimeout(() => {
      setValue(name, selectedPriceItems);
    });
  };

  return (
    <SelectArrayInput
      onChange={handleChange}
      helperText={false}
      optionText="title"
      optionValue="value"
      fullWidth
      label="Khoảng giá"
      source="price"
      choices={filters}
    />
  );
};

const Filters = () => {
  const priceFilters = usePriceFilter();
  const { filterValues, setFilters } = useListContext();
  const defaultValues = useMemo(() => {
    let selectedPriceItems = [];
    if (filterValues.price) {
      const { min, max } = shared.getSelectedPriceFilter(filterValues.price);
      selectedPriceItems = shared.parsePriceFilter(priceFilters, `${min},${max}`);
    }
    return {
      ...filterValues,
      price: selectedPriceItems,
    };
  }, [filterValues, priceFilters]);

  const form = useForm({ defaultValues });

  const onSubmit = values => {
    setFilters(values);
  };

  const handleReset = () => {
    form.reset(DEFAULT_FILTER);
    setFilters(DEFAULT_FILTER);
  };

  return (
    <div>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Box display="flex" alignItems="center" mb={1}>
            <div className="w-full grid grid-cols-3 gap-4">
              <Search />
              <CategoryFilters />
              <PriceFilters filters={priceFilters} />
            </div>

            <Button style={{ marginLeft: 20 }} variant="outlined" color="primary" type="submit">
              Lọc
            </Button>
            <Button onClick={handleReset} variant="outlined" style={{ marginLeft: 10 }} color="error" type="button">
              Xóa
            </Button>
          </Box>
        </form>
      </FormProvider>
    </div>
  );
};

const ProductFilter = ({ hasCreate = true }) => (
  <Box width="100%">
    {hasCreate && (
      <TopToolbar>
        <CreateButton />
      </TopToolbar>
    )}
    <Filters />
  </Box>
);

export default ProductFilter;
