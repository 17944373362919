import AddCardIcon from "@mui/icons-material/AddCard";
import { PaymentList } from "./List";

const PaymentResource = {
  list: PaymentList,
  name: "payment",
  options: { label: "Thanh toán" },
  icon: AddCardIcon,
};

export default PaymentResource;
