import React from "react";
import { TabbedForm, Loading, Empty } from "react-admin";
import Card from "@mui/material/Card";
import { useGetSetting, useSubmitSetting } from "hooks/useSetting";
import StoreSetting from "includes/StoreSetting";
import ProductSetting from "includes/ProductSetting";
import ContactSetting from "includes/ContactSetting";
import PolicySetting from "includes/PolicySetting";
import SettingToolbar from "includes/SettingToolbar";
import HomePageSetting from "includes/HomePageSetting";

export const SettingList = () => {
  const { data, isLoading } = useGetSetting();
  const { trigger, isMutating } = useSubmitSetting();

  const handleSubmit = async values => {
    try {
      await trigger({ values });
    } catch (error) {
      console.log(error);
    }
  };
  if (isLoading) {
    return <Loading />;
  }
  if (!data) {
    return <Empty />;
  }

  return (
    <Card style={{ marginTop: 80 }}>
      <TabbedForm toolbar={<SettingToolbar isLoading={isMutating} />} defaultValues={data} onSubmit={handleSubmit}>
        <TabbedForm.Tab label="Thông tin cửa hàng">
          <StoreSetting />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Nội dung trang chủ">
          <HomePageSetting />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Địa chỉ & Chi nhánh">
          <ContactSetting />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Thiết lập sản phẩm">
          <ProductSetting />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Chính sách bán hàng">
          <PolicySetting />
        </TabbedForm.Tab>
      </TabbedForm>
    </Card>
  );
};
