import { ArrayInput, SimpleFormIterator, TextInput } from "react-admin";
import Grid from "@mui/material/Grid";

const PolicySetting = () => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={12}>
        <ArrayInput label="Chính sách bán hàng:" source="policy">
          <SimpleFormIterator disableClear inline fullWidth>
            <TextInput className="w-[30%]" source="name" label="Tên" />
            <TextInput className="w-[30%]" source="description" label="Mô tả" />
            <TextInput
              placeholder="Ví dụ: card_giftcard"
              helperText={
                <a
                  rel="noopener noreferrer"
                  className="mb-5 block"
                  target="_blank"
                  href="https://fonts.google.com/icons?icon.set=Material+Icons"
                >
                  <span>Hãy truy cập vào đường dẫn này để tìm icon phù hợp</span>
                </a>
              }
              className="w-[30%]"
              source="icon"
              label="Icon hiển thị"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  );
};

export default PolicySetting;
