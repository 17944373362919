import React from "react";
import { Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
import MainLayout from "components/MainLayout";
import shared from "shared";
import http from "providers/HttpProvider";
import OrderResource from "resource/order";
import CategoryResource from "resource/category";
import ProductResource from "resource/product";
import UserResource from "resource/user";
import NewsResource from "resource/news";
import DiscountResource from "resource/discounts";
import PaymentResource from "resource/payment";
import PageResource from "resource/page";
import ChangePassword from "pages/ChangePassword";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";
import LogResource from "resource/log";
import SettingResource from "resource/setting";
import AppearanceResource from "resource/appearance";
import CustomerResource from "resource/customer";

if (typeof window !== "undefined") {
  // Check if we're running in the browser.
  const stringConfig = localStorage.getItem("app-config");
  const config = shared.stringToJSON(stringConfig);
  if (config) {
    shared.setColorSchema(document, config);
    shared.setFavicon(document, config?.favicon?.src);
  } else {
    http
      .get("/common/setting")
      .then(({ data }) => {
        shared.setColorSchema(document, data);
        shared.setFavicon(document, data?.favicon?.src);
        localStorage.setItem("app-config", JSON.stringify(data));
        setTimeout(() => {
          window.location.reload();
        }, 300);
      })
      .catch(error => console.error(error));
  }
}

const App = () => {
  return (
    <MainLayout>
      <CustomRoutes>
        <Route path="/change-password" element={<ChangePassword />} />
      </CustomRoutes>

      <CustomRoutes noLayout>
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </CustomRoutes>

      <CustomRoutes noLayout>
        <Route path="/reset-password" element={<ResetPassword />} />
      </CustomRoutes>

      <Resource {...OrderResource} />

      <Resource {...ProductResource} />

      <Resource {...CustomerResource} />

      <Resource {...CategoryResource} />

      <Resource {...DiscountResource} />

      <Resource {...UserResource} />

      <Resource {...NewsResource} />

      <Resource {...PaymentResource} />

      <Resource {...PageResource} />

      <Resource {...AppearanceResource} />

      <Resource {...SettingResource} />

      <Resource {...LogResource} />
    </MainLayout>
  );
};

export default App;
