import useSWR from "swr";
import http from "providers/HttpProvider";

const fetcher = async params => {
  const queries = new URLSearchParams(params);
  const { type, id } = Object.fromEntries(queries.entries());
  const { data } = await http.get("/common/address", {
    params: { type, id },
  });
  return data;
};

const useAddress = (type, id) => {
  const { data, ...rest } = useSWR(`type=${type}&id=${id}`, fetcher);
  return {
    ...data,
    ...rest,
  };
};

export default useAddress;
