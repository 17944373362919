import React, { useState, useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  FunctionField,
  Pagination,
  useRefresh,
  DeleteWithConfirmButton,
} from "react-admin";
import shared from "shared";
import { useOrderUpdate } from "hooks/useOrder";
import IconButton from "@mui/material/IconButton";
import SyncIcon from "@mui/icons-material/Sync";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OrderExpand from "includes/OrderExpand";
import OrderFilter from "includes/OrderFilter";

const StatusActions = ({ record }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const refresh = useRefresh();
  const onSuccess = useCallback(() => {
    refresh();
  }, [refresh]);
  const { trigger } = useOrderUpdate({ onSuccess });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = async item => {
    try {
      handleClose();
      await trigger({
        id: record.id,
        status: item.VALUE,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const renderItem = (item, index) => {
    return (
      <MenuItem key={index} onClick={() => handleSelect(item)}>
        {item.TEXT}
      </MenuItem>
    );
  };

  if (
    record.status === shared.Constants.ORDER_STATUS.DONE.VALUE ||
    record.status === shared.Constants.ORDER_STATUS.CANCELED.VALUE
  ) {
    return null;
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <SyncIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {Object.values(shared.Constants.ORDER_STATUS).map(renderItem)}
      </Menu>
    </div>
  );
};

const StatusField = () => {
  return (
    <FunctionField
      render={record => {
        const data = shared.getOrderStatus(record.status);
        if (!data) {
          return null;
        }
        return (
          <div className="flex items-center justify-start">
            <div className="mr-1">
              <span
                style={{ backgroundColor: data.COLOR }}
                className="font-semibold text-white py-1 px-2 rounded text-sm"
              >
                {data.TEXT}
              </span>
            </div>
            <StatusActions record={record} />
          </div>
        );
      }}
    />
  );
};

const DeleteButton = () => {
  return (
    <FunctionField
      render={record => {
        if (record.status === shared.Constants.ORDER_STATUS.DONE) {
          return null;
        }
        return (
          <DeleteWithConfirmButton
            confirmTitle="Bạn có muốn xóa dữ liệu này?"
            confirmContent="Bạn sẽ không thể khôi phục dữ liệu sau khi xóa"
          />
        );
      }}
    />
  );
};

export const OrderList = () => (
  <List
    actions={<OrderFilter />}
    exporter={false}
    sort={{ field: "createdAt", order: "DESC" }}
    perPage={shared.Constants.PAGE_SIZE}
    pagination={<Pagination rowsPerPageOptions={[shared.Constants.PAGE_SIZE]} />}
  >
    <Datagrid expandSingle expand={<OrderExpand />} bulkActionButtons={false}>
      <DateField locales="vi-VN" label="Ngày tạo" source="createdAt" />
      <TextField sortable={false} label="Mã đơn hàng" source="code" />
      <StatusField label="Trạng thái" />
      <TextField sortable={false} label="Tên khách hàng" source="customer.name" />
      <TextField sortable={false} label="Số điện thoại" source="customer.phone" />
      <NumberField
        className="!font-semibold !text-red-600"
        sortable={false}
        label="Tiền hàng"
        source="total"
        locales="vi-VN"
        options={{ style: "currency", currency: "VND" }}
      />
      <DeleteButton />
    </Datagrid>
  </List>
);
