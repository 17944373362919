import { List, Datagrid, TextField, DeleteWithConfirmButton, FunctionField, useGetIdentity } from "react-admin";
import shared from "shared";

const DeleteUserButton = () => {
  const { identity } = useGetIdentity();
  const canDeleted = identity?.role !== shared.Constants.ROLE_TYPES.LIMITED;

  return (
    <FunctionField
      render={record => {
        if (canDeleted && identity?.id !== record.id) {
          return (
            <DeleteWithConfirmButton
              confirmTitle="Bạn có muốn xóa dữ liệu này?"
              confirmContent="Bạn sẽ không thể khôi phục dữ liệu sau khi xóa"
            />
          );
        }
        return null;
      }}
    />
  );
};

const RoleField = () => {
  return (
    <FunctionField
      render={record => {
        let displayText = null;
        switch (record?.role) {
          case shared.Constants.ROLE_TYPES.FULL:
            displayText = "Quản trị viên";
            break;
          case shared.Constants.ROLE_TYPES.LIMITED:
            displayText = "Nhân viên";
            break;
          case shared.Constants.ROLE_TYPES.OWNER:
            displayText = "Chủ cửa hàng";
            break;
          default:
            break;
        }
        return <span>{displayText}</span>;
      }}
    />
  );
};

export const UserList = () => {
  const { identity } = useGetIdentity();

  return (
    <List hasCreate={identity?.role !== shared.Constants.ROLE_TYPES.LIMITED} exporter={false}>
      <Datagrid bulkActionButtons={false}>
        <TextField label="Tên" source="name" />
        <TextField label="Email" source="email" />
        <RoleField label="Quyền truy cập" />
        <DeleteUserButton />
      </Datagrid>
    </List>
  );
};
