import React, { useMemo } from "react";
import { required, TextInput, ArrayInput, SimpleFormIterator, useInput, TabbedForm, Loading, Empty } from "react-admin";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import useBanks from "hooks/useBanks";
import { useGetSetting, useSubmitSetting } from "hooks/useSetting";
import SettingToolbar from "includes/SettingToolbar";

const BankSelect = ({ label = "Ngân hàng", source = "accountBankName" }) => {
  const { data, isLoading } = useBanks();
  const currentField = useInput({ source });

  const handleSelect = event => {
    currentField.field.onChange(event);
  };

  const selectedValue = useMemo(() => {
    if (!data) {
      return null;
    }
    const value = data.find(v => `${v.name} (${v.shortName})` === currentField.field.value);
    return value ?? null;
  }, [data, currentField]);

  return (
    <div className="w-[32%]">
      <Autocomplete
        value={selectedValue}
        onSelect={handleSelect}
        autoComplete={true}
        options={data ?? []}
        getOptionLabel={option => `${option?.name} (${option.shortName})`}
        renderOption={(props, option) => (
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
            <img width="50" src={option.logo} alt="" />
            {option.name} ({option.shortName})
          </Box>
        )}
        renderInput={params => {
          return (
            <TextInput
              {...params}
              disabled={isLoading}
              validate={[required()]}
              source={source}
              label={isLoading ? "Đang tải" : label}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          );
        }}
      />
    </div>
  );
};

const PaymentSetting = () => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={12}>
        <ArrayInput label="Tài khoản ngân hàng:" source="accountBanks">
          <SimpleFormIterator disableReordering disableClear inline fullWidth>
            <BankSelect label="Ngân hàng" source="accountBankName" />
            <TextInput className="w-[32%]" label="Số tài khoản" source="accountNumber" validate={[required()]} />
            <TextInput className="w-[32%]" label="Tên tài khoản" source="accountName" validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  );
};

export const PaymentList = () => {
  const { data, isLoading } = useGetSetting();
  const { trigger, isMutating } = useSubmitSetting();

  const handleSubmit = async values => {
    try {
      await trigger({ values });
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return <Empty />;
  }

  return (
    <Card style={{ marginTop: 80 }}>
      <TabbedForm toolbar={<SettingToolbar isLoading={isMutating} />} defaultValues={data} onSubmit={handleSubmit}>
        <TabbedForm.Tab label="Phương thức thanh toán">
          <PaymentSetting />
        </TabbedForm.Tab>
      </TabbedForm>
    </Card>
  );
};
