import useSWR from "swr";
import http from "providers/HttpProvider";

const fetcher = async () => {
  const { data } = await http.get("/common/banks");
  return data;
};

const useBanks = () => {
  const { data, ...rest } = useSWR("get-banks", fetcher);
  return {
    ...data,
    ...rest,
  };
};

export default useBanks;
