import React, { useMemo } from 'react';
import {
  SelectInput,
  useGetList,
  AutocompleteInput,
  SelectArrayInput,
} from 'react-admin';

const SelectResourceInput = ({
  validate,
  resource,
  label,
  source,
  optionValue = 'id',
  optionText = 'name',
  params = {},
  multiple = false,
}) => {
  const { data, isLoading } = useGetList(resource, params);

  const choices = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return data;
  }, [data, isLoading]);

  const SelectContainer = multiple
    ? SelectArrayInput
    : choices.length > 10
    ? AutocompleteInput
    : SelectInput;

  return (
    <SelectContainer
      validate={validate}
      choices={choices}
      source={source}
      optionValue={optionValue}
      optionText={optionText}
      label={label}
      fullWidth
    />
  );
};

export default React.memo(SelectResourceInput);
