import React from "react";
import { TabbedForm, Loading, Empty } from "react-admin";
import Card from "@mui/material/Card";
import { useGetSetting, useSubmitSetting } from "hooks/useSetting";
import Grid from "@mui/material/Grid";
import ColorInput from "components/FormControls/ColorInput";
import ImagePicker from "components/FormControls/ImagePicker";
import SettingToolbar from "includes/SettingToolbar";

const ThemeColors = () => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={6}>
        <div className="min-h-[250px]">
          <ColorInput source="primaryColor" label="Màu chính" />
        </div>
      </Grid>

      <Grid item xs={6}>
        <div className="min-h-[250px]">
          <ColorInput source="secondaryColor" label="Màu phụ" />
        </div>
      </Grid>
    </Grid>
  );
};

const Images = () => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={6}>
        <ImagePicker source="logo" label="Logo cửa hàng" />
      </Grid>

      <Grid item xs={6}>
        <ImagePicker source="banner" label="Hình ảnh cửa hàng" />
      </Grid>

      <Grid item xs={6}>
        <ImagePicker source="favicon" label="Favicon" />
      </Grid>
    </Grid>
  );
};

export const AppearanceList = () => {
  const { data, isLoading } = useGetSetting();
  const { trigger, isMutating } = useSubmitSetting(true);

  const handleSubmit = async values => {
    try {
      await trigger({ values });
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return <Empty />;
  }

  return (
    <Card style={{ marginTop: 80 }}>
      <TabbedForm toolbar={<SettingToolbar isLoading={isMutating} />} defaultValues={data} onSubmit={handleSubmit}>
        <TabbedForm.Tab label="Màu sắc">
          <ThemeColors />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Logo & Favicon">
          <Images />
        </TabbedForm.Tab>
      </TabbedForm>
    </Card>
  );
};
