import React, { useState, useRef } from 'react';
import { useInput } from 'react-admin';
import { HexColorPicker } from 'react-colorful';
import TextField from '@mui/material/TextField';

const ColorInput = ({ source, label = 'Màu sắc' }) => {
  const timout = useRef(null);
  const { field } = useInput({ source });
  const [color, setColor] = useState(field.value);
  const [open, setOpen] = useState(false);

  const handleColorChange = (color) => {
    setColor(color);
    field.onChange(color);

    timout.current && clearTimeout(timout.current);

    timout.current = setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  const handleChange = (event) => {
    handleColorChange(event.target.value);
  };

  const handleFocus = () => {
    setOpen(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        onChange={handleChange}
        focused={open || !!color}
        value={color}
        onFocus={handleFocus}
        InputProps={{
          endAdornment: (
            <div
              style={{
                backgroundColor: color,
                height: 30,
                width: 50,
              }}
            ></div>
          ),
        }}
        label={label}
        fullWidth
      />
      {open && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: '#fff',
            zIndex: 2,
            right: 0,
          }}
        >
          <HexColorPicker color={color} onChange={handleColorChange} />
        </div>
      )}
    </div>
  );
};

export default ColorInput;
