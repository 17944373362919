import http from "providers/HttpProvider";
import { useMemo } from "react";
import useSWRInfinite from "swr/infinite";

const getKey = (pageIndex, previousPageData, resource) => {
  if (previousPageData && !previousPageData.hasNextPage) return null; // reached the end
  return `page=${pageIndex + 1}&limit=15&resource=${resource}`; // SWR key
};

const fetcher = async params => {
  const queries = new URLSearchParams(params);
  const { page, limit, resource } = Object.fromEntries(queries.entries());
  const { data } = await http.get(`/${resource}`, {
    params: {
      page,
      limit,
      order: "name,desc",
    },
  });
  return data;
};

const useListPagination = resource => {
  const { data, size, setSize, isLoading } = useSWRInfinite(
    (index, previousPageData) => getKey(index, previousPageData, resource),
    fetcher,
  );
  const items = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.reduce((results, items) => {
      results = [...results, ...items.data];
      return results;
    }, []);
  }, [data]);

  return {
    data: items,
    size,
    setSize,
    isLoading,
  };
};

export default useListPagination;
