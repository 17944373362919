import { useMemo } from "react";
import shared from "shared";
import useAppConfig from "./useAppConfig";

const usePriceFilter = () => {
  const config = useAppConfig();

  return useMemo(() => {
    if (config && config.data) {
      return shared.getPriceFilter(config.data.priceFilters);
    }
    return [];
  }, [config]);
};

export default usePriceFilter;
