import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import shared from "shared";
import http from "./HttpProvider";
import FileProvider from "./FileProvider";

const apiUrl = "https://my.api.com/";
const httpClient = fetchUtils.fetchJson;

const uploadVariantImages = async values => {
  const uploadingVariantImages = values.variants.reduce((result, item, index) => {
    if (item.image && item.image.rawFile) {
      result.push({ ...item, index });
    } else if (item.image === "") {
      // Need to convert from string empty to null (Else: Server will error)
      values.variants[index].image = null;
    }
    return result;
  }, []);

  if (uploadingVariantImages.length > 0) {
    const promises = uploadingVariantImages.map(item => {
      return new Promise((resolve, reject) => {
        FileProvider.uploadImage(item.image.rawFile)
          .then(response => resolve({ ...item, image: response }))
          .catch(error => reject(error));
      });
    });
    const images = await Promise.all(promises);

    for (const item of images) {
      const { index, ...rest } = item;
      values.variants[index] = rest;
      if (!values.images) {
        values.images = [];
      }
      // Add variant images to image list
      values.images.push(item.image);
    }
  }

  return values;
};

const getQueryParams = (resource, params) => {
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  const filter = params.filter;
  let query = {
    limit: perPage,
    page,
    order: `${field},${order === "ASC" ? "asc" : "desc"}`,
  };

  if (params.meta) {
    query = { ...query, ...params.meta };
  }
  if (Object.keys(filter).length > 0) {
    const keyword = filter.q ? filter.q : "";
    if (keyword) {
      query = { ...query, keyword };
    }
    if (resource === "products") {
      const price = filter.price ? filter.price : [];
      const categories = filter.categories ? filter.categories : [];

      if (price.length > 0) {
        const { min, max } = shared.getSelectedPriceFilter(price);
        query = { ...query, price: `${min},${max}` };
      }
      if (categories.length > 0) {
        query = { ...query, categories: categories.join(",") };
      }
    } else if (resource === "orders") {
      if (filter.start && filter.end) {
        query = {
          ...query,
          start: filter.start,
          end: filter.end,
        };
      }
    }
  }
  return query;
};

const uploadImages = async (values, resource) => {
  switch (resource) {
    case "products":
      if (values.thumbnail && values.thumbnail.rawFile) {
        values.thumbnail = await FileProvider.uploadImage(values.thumbnail.rawFile, { width: 300 });
      }
      values = await uploadVariantImages(values);
      values.images = await FileProvider.uploadImages(values.images);
      break;
    case "categories":
      if (values.icon && values.icon.rawFile) {
        values.icon = await FileProvider.uploadImage(values.icon.rawFile);
      }
      if (values.banner && values.banner.rawFile) {
        values.banner = await FileProvider.uploadImage(values.banner.rawFile);
      }
      break;
    case "news":
      if (values.thumbnail && values.thumbnail.rawFile) {
        values.thumbnail = await FileProvider.uploadImage(values.thumbnail.rawFile);
      }
      break;
    default:
      break;
  }
  return values;
};

const AppProvider = {
  update: async (resource, params) => {
    try {
      const body = await uploadImages(params.data, resource);
      const { data } = await http.put(`/${resource}/${params.id}`, body);
      FileProvider.clearStorage();
      return data;
    } catch (error) {
      // Need to delete images that uploaded before
      FileProvider.removeUploadedImagesWhenError();
      throw error;
    }
  },

  create: async (resource, params) => {
    try {
      const body = await uploadImages(params.data, resource);
      const { data } = await http.post(`/${resource}`, body);
      FileProvider.clearStorage();
      return data;
    } catch (error) {
      // Need to delete images that uploaded before
      FileProvider.removeUploadedImagesWhenError();
      throw error;
    }
  },

  getList: async (resource, params) => {
    if (!resource) {
      return Promise.resolve({ data: [], total: 0 });
    }
    const query = getQueryParams(resource, params);
    const { data } = await http.get(`/${resource}`, {
      params: query,
    });
    return data;
  },

  getOne: async (resource, params) => {
    const { data } = await http.get(`/${resource}/${params.id}`);
    return data;
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: async (resource, params) => {
    const { data } = await http.delete(`/${resource}/${params.id}`);
    return data;
  },

  deleteMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const { data } = await http.delete(`/${resource}`, {
      params: query,
    });
    return data;
  },
};

export default AppProvider;
