import { useCallback } from "react";
import { useNotify } from "react-admin";
import useSWRMutation from "swr/mutation";
import useSWR from "swr";
import http from "providers/HttpProvider";
import FileProvider from "providers/FileProvider";

const fetchSubmit = async (_, { arg }) => {
  if (arg.values.logo && arg.values.logo.rawFile) {
    const response = await FileProvider.uploadImage(arg.values.logo.rawFile, {
      public_id: "logo",
      width: 300,
    });
    arg.values.logo = response;
  }
  if (arg.values.banner && arg.values.banner.rawFile) {
    const response = await FileProvider.uploadImage(arg.values.banner.rawFile, {
      public_id: "banner",
    });
    arg.values.banner = response;
  }
  if (arg.values.favicon && arg.values.favicon.rawFile) {
    const response = await FileProvider.uploadImage(arg.values.favicon.rawFile, {
      public_id: "favicon",
    });
    arg.values.favicon = response;
  }
  if (arg.values.slides && arg.values.slides.some(item => item.image && item.image.rawFile)) {
    const promies = arg.values.slides
      .filter(item => item.image && item.image.rawFile)
      .map(item => FileProvider.uploadImage(item.image.rawFile, { width: 800 }));
    const uploadedImages = await Promise.all(promies);
    const newImages = uploadedImages.map(item => {
      return { image: item };
    });
    const oldImages = arg.values.slides.filter(item => item.image && !item.image.rawFile);
    arg.values.slides = [...oldImages, ...newImages];
  }
  return http.post("/common/setting", arg);
};

const fetcheGet = async () => {
  const { data } = await http.get("/common/setting");
  return data;
};

export const useSubmitSetting = (reloadWhenSuccess = false) => {
  const notify = useNotify();

  const handleSuccess = useCallback(() => {
    notify(`Đã lưu thành công`, { type: "success" });
    if (reloadWhenSuccess) {
      setTimeout(() => {
        window.location.reload();
      }, 300);
    }
  }, [notify, reloadWhenSuccess]);

  const handleError = useCallback(
    error => {
      notify(error?.message ?? "Đã xảy ra lỗi, vui lòng thử lại sau", { type: "error" });
    },
    [notify],
  );

  const { trigger, isMutating, error } = useSWRMutation("submmit-setting", fetchSubmit, {
    onSuccess: handleSuccess,
    onError: handleError,
  });
  return {
    trigger,
    isMutating,
    error,
  };
};

export const useGetSetting = () => {
  const { data, isLoading, ...rest } = useSWR("get-subbmit", fetcheGet);
  return {
    ...data,
    isLoading,
    ...rest,
  };
};
