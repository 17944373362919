import RedeemIcon from "@mui/icons-material/Redeem";
import { DiscountList } from "./List";
import { DiscountCreate, DiscountEdit } from "./CreateEdit";

const DiscountResource = {
  list: DiscountList,
  create: DiscountCreate,
  edit: DiscountEdit,
  name: "discounts",
  options: { label: "Khuyến mãi" },
  icon: RedeemIcon,
};

export default DiscountResource;
