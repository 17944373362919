import { required, ArrayInput, SimpleFormIterator, BooleanInput } from "react-admin";
import Grid from "@mui/material/Grid";
import CurrencyInput from "components/FormControls/CurrencyInput";
import TextEditor from "components/FormControls/TextEditor";

const ProductSetting = () => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={12}>
        <p className="font-semibold m-0">Hỗ trợ giao hàng</p>
        <BooleanInput label="Lấy phí giao hàng từ đơn vị vận chuyển?" source="isSupportShipping" />
      </Grid>
      <Grid item xs={12}>
        <p className="font-semibold m-0">Bộ lọc giá sản phẩm</p>
      </Grid>

      <Grid item xs={12}>
        <ArrayInput label="Các khoảng giá khác:" source="priceFilters">
          <SimpleFormIterator disableReordering disableClear inline fullWidth>
            <CurrencyInput validate={[required()]} className="w-[46%]" source="min" label="Từ khoảng giá" />
            <CurrencyInput validate={[required()]} className="w-[46%]" source="max" label="Đến khoảng giá" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>

      <Grid item xs={12}>
        <p className="font-semibold">Nội dung khuyến mãi - ưu đãi chung</p>
      </Grid>

      <Grid item xs={12}>
        <TextEditor source="commonPromotion" />
      </Grid>
    </Grid>
  );
};

export default ProductSetting;
