import {
  required,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  regex,
  BooleanInput,
  RadioButtonGroupInput,
} from "react-admin";
import Grid from "@mui/material/Grid";
import ImagePicker from "components/FormControls/ImagePicker";
import shared from "shared";

/* eslint-disable no-useless-escape */
const URL_REGEX =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

const getImageSize = () => {
  const { LUXYRY, CLASSIC, MODERN } = shared.Constants.LAYOUT_HEADER_TYPES;
  switch (process.env.REACT_APP_LAYOUT_HEADER) {
    case LUXYRY:
      return "3x1";
    case CLASSIC:
      return "2x1";
    case MODERN:
      return "2x1";
    default:
      return "";
  }
};

const HomePageSetting = () => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={12}>
        <p className="font-semibold m-0">Danh mục sản phẩm</p>
        <BooleanInput label="Hiển thị danh mục sản phẩm?" source="isVisiableCategories" />
      </Grid>

      <Grid item xs={12}>
        <RadioButtonGroupInput
          label="Nút liên hệ nhanh"
          source="quickContactButton"
          choices={[
            { id: "messenger", name: "Messenger (Facebook)" },
            { id: "zalo", name: "Zalo" },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <p className="font-semibold m-0">Hình ảnh Slide (Tỉ lệ đề xuất: {getImageSize()})</p>
      </Grid>

      <Grid item xs={12}>
        <ArrayInput validate={[required()]} label={false} source="slides">
          <SimpleFormIterator
            getItemLabel={index => <span className="text-xs text-gray-500">Slide {index + 1}</span>}
            disableClear
            inline
            fullWidth
          >
            <TextInput
              validate={[regex(URL_REGEX, "Đường dẫn không đúng")]}
              label="Đường dẫn"
              source="link"
              fullWidth
            />
            <ImagePicker source="image" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  );
};

export default HomePageSetting;
