import React from 'react';
import { TextInput } from 'react-admin';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const Search = ({ label = 'Tìm kiếm...', source = 'q' }) => {
  return (
    <div>
      <TextInput
        fullWidth
        resettable
        helperText={false}
        source={source}
        label={label}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon color="disabled" />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default Search;
