import React, { useMemo } from "react";
import { TopToolbar, useListContext } from "react-admin";
import { Box, Button } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import dayjs from "dayjs";
import Search from "components/FormControls/Search";
import RangeDatePicker from "components/FormControls/RangeDatePicker";

const DEFAULT_FILTER = {
  q: "",
  start: null,
  end: null,
};

const MAX_DATE = dayjs();

const Filters = () => {
  const { filterValues, setFilters } = useListContext();
  const defaultValues = useMemo(() => {
    return filterValues;
  }, [filterValues]);

  const form = useForm({ defaultValues });

  const onSubmit = values => {
    setFilters(values);
  };

  const handleReset = () => {
    form.reset(DEFAULT_FILTER);
    setFilters(DEFAULT_FILTER);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="center" mb={1}>
          <div className="w-full grid grid-cols-2 gap-4">
            <Search label="Tìm kiếm theo mã đơn hàng hoặc số điện thoại" />
            <RangeDatePicker maxDate={MAX_DATE} defaultValues={defaultValues} />
          </div>
          <Button style={{ marginLeft: 20 }} variant="outlined" color="primary" type="submit">
            Lọc
          </Button>
          <Button onClick={handleReset} variant="outlined" style={{ marginLeft: 10 }} color="error" type="button">
            Xóa
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};

const OrderFilter = () => (
  <Box width="100%">
    <TopToolbar />
    <Filters />
  </Box>
);

export default OrderFilter;
