import WebIcon from "@mui/icons-material/Web";
import { PageList } from "./List";
import { PageEdit, PageCreate } from "./CreateEdit";

const PageResource = {
  list: PageList,
  create: PageCreate,
  edit: PageEdit,
  name: "pages",
  options: { label: "Nội dung trang" },
  icon: WebIcon,
};

export default PageResource;
