import React from "react";
import { Toolbar, SaveButton } from "react-admin";
import LoadingProgress from "components/LoadingProgress";
import SaveIcon from "@mui/icons-material/Save";

const SettingToolbar = ({ isLoading }) => {
  return (
    <Toolbar>
      <SaveButton disabled={isLoading} icon={isLoading ? <LoadingProgress /> : <SaveIcon />} label="Lưu" />
    </Toolbar>
  );
};

export default SettingToolbar;
