import { Edit, Create, SimpleForm, TextInput, required, Toolbar, SaveButton } from "react-admin";
import React from "react";
import ImagePicker from "components/FormControls/ImagePicker";

const FormToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const Form = () => {
  return (
    <SimpleForm toolbar={<FormToolbar />}>
      <TextInput label="Tên danh mục" source="name" validate={[required()]} fullWidth />
      <ImagePicker source="icon" label="Icon" />
      <ImagePicker source="banner" label="Banner" />
    </SimpleForm>
  );
};

export const CategoryEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect="list" title="Chỉnh sửa">
      <Form />
    </Edit>
  );
};

export const CategoryCreate = () => {
  return (
    <Create redirect="list">
      <Form />
    </Create>
  );
};
