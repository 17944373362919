import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { UserList } from "./List";
import { UserCreate } from "./CreateEdit";

const UserResource = {
  list: UserList,
  create: UserCreate,
  name: "users",
  options: { label: "Quản trị viên" },
  icon: AdminPanelSettingsIcon,
};

export default UserResource;
