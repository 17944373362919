import { Create, SimpleForm, TextInput, required, email, SelectInput } from "react-admin";
import React from "react";
import shared from "shared";

const transform = values => {
  return {
    ...values,
    password: `MK${new Date().getFullYear()}$`,
  };
};

const Form = () => {
  return (
    <SimpleForm>
      <TextInput label="Tên" source="name" validate={[required()]} fullWidth />
      <TextInput label="Email" source="email" validate={[required(), email()]} fullWidth />
      <SelectInput
        defaultValue={shared.Constants.ROLE_TYPES.LIMITED}
        validate={[required()]}
        fullWidth
        label="Vai trò"
        source="role"
        choices={[
          { id: shared.Constants.ROLE_TYPES.FULL, name: "Quản trị viên" },
          { id: shared.Constants.ROLE_TYPES.LIMITED, name: "Nhân viên" },
        ]}
      />
      <p style={{ margin: 0 }}>
        <span style={{ fontStyle: "italic" }}>Mật khẩu mặc định: </span>
        <strong>MK{new Date().getFullYear()}$</strong>
      </p>
    </SimpleForm>
  );
};

export const UserCreate = () => {
  return (
    <Create transform={transform} redirect="list">
      <Form />
    </Create>
  );
};
