import CategoryIcon from "@mui/icons-material/Category";
import { CategoryList } from "./List";
import { CategoryCreate, CategoryEdit } from "./CreateEdit";

const CategoryResource = {
  list: CategoryList,
  create: CategoryCreate,
  edit: CategoryEdit,
  name: "categories",
  options: { label: "Danh mục" },
  icon: CategoryIcon,
};

export default CategoryResource;
