import { useCallback } from "react";
import { useRefresh } from "react-admin";
import shared from "shared";
import Button from "@mui/material/Button";
import { useOrderUpdate } from "hooks/useOrder";

const Products = ({ data }) => {
  const renderItem = (item, index) => {
    return (
      <div className="border mb-2 bg-white shadow-sm" key={index}>
        <div className="flex">
          <img className="min-w-[70px] h-[70px] object-cover rounded" src={item.thumbnail?.src} alt={item.name} />
          <div className="px-2 py-1">
            <p className="m-0 text-sm">
              <span>{item.name}</span>
              {item.variants && item.variants.length > 0 && (
                <span className="italic font-semibold ml-1">({item.variants[0].value})</span>
              )}
            </p>
            <div className="flex items-center mt-1">
              <span className="font-medium text-sm">SL: {item.quantity}</span>
              <span className="text-red-500 text-sm font-semibold ml-2">{shared.currencyFormat(item.price)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <div className="col-span-2">{data.map(renderItem)}</div>;
};

const Customer = ({ data, note, id, status }) => {
  const refresh = useRefresh();
  const onSuccess = useCallback(() => {
    refresh();
  }, [refresh]);
  const { trigger } = useOrderUpdate({ onSuccess });

  const handleUpdateStatus = async value => {
    try {
      await trigger({ id, status: value });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="border mb-2 bg-white shadow-sm p-3 pt-0 col-span-3">
      <div>
        <p className="mb-1.5">
          - Khách hàng: <span className="font-semibold">{data.name}</span>
        </p>
        <p className="mb-1.5">
          - Số điện thoại: <span className="font-semibold">{data.phone}</span>
        </p>
        <p className="mb-1.5">
          - Địa chỉ: <span className="font-semibold">{data.address}</span>
        </p>
        <p className="mb-1.5">
          - Ghi chú: <span className="font-semibold ">{note || "Không có"}</span>
        </p>
      </div>
      <div className="grid grid-cols-3 gap-4 mt-5 xl:grid-cols-1">
        {status === shared.Constants.ORDER_STATUS.WATING_CONFIRM.VALUE && (
          <Button
            onClick={() => handleUpdateStatus(shared.Constants.ORDER_STATUS.DELIVERING.VALUE)}
            color="secondary"
            variant="contained"
          >
            Giao hàng
          </Button>
        )}
        {status !== shared.Constants.ORDER_STATUS.CANCELED.VALUE &&
          status !== shared.Constants.ORDER_STATUS.DONE.VALUE && (
            <Button
              onClick={() => handleUpdateStatus(shared.Constants.ORDER_STATUS.CANCELED.VALUE)}
              color="error"
              variant="outlined"
            >
              Hủy đơn
            </Button>
          )}
      </div>
    </div>
  );
};

const OrderExpand = ({ record }) => {
  const { products, customer, note, id, status } = record;
  return (
    <div className="bg-gray-50 p-3">
      <div className="grid grid-cols-5 gap-4 lg:grid-cols-1 lg:gap-2">
        <Products id={id} data={products} />
        <Customer status={status} id={id} data={customer} note={note} />
      </div>
    </div>
  );
};

export default OrderExpand;
