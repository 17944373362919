import InventoryIcon from "@mui/icons-material/Inventory";
import { ProductList } from "./List";
import { ProductCreate, ProductEdit } from "./CreateEdit";

const ProductResource = {
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
  name: "products",
  options: { label: "Sản phẩm" },
  icon: InventoryIcon,
};

export default ProductResource;
