import React, { useEffect, useRef } from "react";
import { Editor as TinymceEditor } from "@tinymce/tinymce-react";
import { useInput } from "react-admin";
import FileProvider from "providers/FileProvider";

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from "tinymce/tinymce";
// DOM model
import "tinymce/models/dom/model";
// Theme
import "tinymce/themes/silver";
// Toolbar icons
import "tinymce/icons/default";
// Editor styles
import "tinymce/skins/ui/oxide/skin.css";

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autosave";
import "tinymce/plugins/image";
import "tinymce/plugins/importcss";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/save";
import "tinymce/plugins/table";
import "tinymce/plugins/template";

const Regex = /[^\\/]+?(?=\.\w+$)/g;

export default function TextEditor({ source, height, width = "100%", label }) {
  const editorRef = useRef(null);
  const timeoutRef = useRef(null);
  const { field } = useInput({ source });

  const handleChange = text => {
    field.onChange(text);
  };

  const handleUploadImage = blobInfo => {
    return new Promise(async (resolve, reject) => {
      try {
        const file = blobInfo.blob();
        const image = await FileProvider.uploadImage(file);
        resolve(image.src);
      } catch (error) {
        console.log("handleUploadImage", error);
        reject(error);
      }
    });
  };

  const handleDelete = async selectedNode => {
    try {
      // Get public_id from url
      const imageSrc = selectedNode.src;
      const publicId = imageSrc.match(Regex)[0];
      await FileProvider.deleteImage(publicId);
    } catch (error) {
      console.log("[ERROR] handleDelete", error);
    }
  };

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      const { selection } = tinymce.activeEditor;
      if (editorRef.current && selection) {
        editorRef.current.on("keydown", async function (event) {
          if ((event.keyCode === 8 || event.keyCode === 46) && selection) {
            const selectedNode = tinymce.activeEditor.selection.getNode();
            switch (selectedNode?.nodeName) {
              case "IMG":
                await handleDelete(selectedNode);
                break;
              default:
                break;
            }
          }
        });
      }
    }, 1000);

    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  return (
    <>
      <span className="tox-tinymce-label">{label}</span>
      <TinymceEditor
        onInit={(evt, editor) => (editorRef.current = editor)}
        onEditorChange={handleChange}
        value={field.value ?? ""}
        init={{
          skin: false,
          language: "vi",
          language_url: "/resource/vi.js", // site absolute URL
          content_css: "/resource/tinymce.css",
          height: height ?? 450,
          width,
          menubar: true,
          removed_menuitems: "fontfamily",
          plugins: ["advlist", "anchor", "image", "link", "lists", "table", "media"],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat",
          body_class: "tinymce-rich-text",
          images_upload_handler: handleUploadImage,
        }}
      />
    </>
  );
}
