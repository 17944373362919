import http from "./HttpProvider";
import shared from "shared";

export const removeLocalStorageItems = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");
};

const getUser = () => {
  const data = localStorage.getItem("user");
  return shared.stringToJSON(data);
};

const AuthProvider = {
  login: async ({ email, password }) => {
    const { data } = await http.post("/auth/login", {
      email,
      password,
    });
    const { accessToken, refreshToken, ...rest } = data.data;
    localStorage.setItem("token", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("user", JSON.stringify(rest));
    return Promise.resolve();
  },
  // called when the user clicks on the logout button
  logout: async () => {
    try {
      await http.post("/auth/logout");
    } catch (error) {
      console.error(error);
    } finally {
      removeLocalStorageItems();
    }
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    if (window.location.href.includes("forgot-password") || window.location.href.includes("reset-password")) {
      return Promise.resolve();
    }
    const token = localStorage.getItem("token");
    return token ? Promise.resolve() : Promise.reject({ redirectTo: "/login" });
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const user = getUser();
    if (user) {
      return Promise.resolve(user.role);
    }
    return Promise.reject();
  },

  getIdentity: async () => {
    const user = getUser();
    if (user) {
      const { name, id, role } = user;
      return Promise.resolve({
        fullName: name,
        avatar: `https://ui-avatars.com/api/?name=${name}?bold=true`,
        id,
        role,
      });
    }
    return Promise.reject();
  },

  signUp: async values => {
    return await http.post("/auth/register", values);
  },
};

export default AuthProvider;
